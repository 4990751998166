.blacklist{
    display:flex;
    flex-direction:column;
    align-items: center;
    height: auto;

}

.blacklist-header-icon{
    color: rgb(239, 0, 0) !important;
}


.blacklist-info{
    width: 100%;
}

.blacklist-left{
    display: flex;
    align-items: end !important;
}

.blacklist-input-group{
    margin-right: 1rem !important;
}

.blacklist-table {
    margin-top: 1rem;
}