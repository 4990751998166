.admin-controller {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-controller-header {
  height: 20rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.admin-button {
  width: 23rem;
  height: 10rem;
  background-color: #0e1825;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: #f0f0f05f;

  border-radius: 15px;

  box-shadow: 0px 0px 10px 0px #000000;
  transition: all 0.1s ease-in-out;
}

.admin-button:hover {
  box-shadow: 0px 0px 10px 0px #f0f0f0;
  color: #f0f0f0;
  cursor: pointer;
}

.admin-button:active {
  box-shadow: 0px 0px 10px 0px #f0f0f0;
  transform: scale(0.95);
}

.admin-button-icon {
  font-size: 5rem !important;
}

.admin-button-active {
  color: #f0f0f0 !important;
}

.selected-control-panel .control-panel-body {
  width: 100%;
  padding: 0;
  margin: 0;
}

.control-panel-header {
  font-size: 2rem;
  width: 100%;
  border-bottom: 1px solid #2e384a;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
}

.admin-controller-body {
  border-top: 1px solid #242b36;
  height: auto;
}

.control-panel-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  height: 80rem;
  justify-content: center;
}

.control-panel-trader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  height: 40rem;
  justify-content: center;
}
.user-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 4rem;
}

.button-copy {
  position: absolute;
  top: 1rem;
  right: 1rem;

  /* cursor: pointer; */
}

.copy-button:hover {
  background-color: #0056b3;
}

.control-panel-user-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
