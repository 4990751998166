.panel-main {
  display: flex;
  flex-direction: row;

  height: 100vh;
  width: 100%;
  background-color: #1a2234;
}

.panel-nav {
  display: flex;
  flex-direction: column;

  width: 280px;
  background-color: #0e1825;
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.1);

  height: 100%;
  overflow: auto;
  transition: all;
}

.panel-nav-hide {
  width: 0;
}

@media only screen and (max-width: 1140px) {
  .panel-nav {
    display: none;
  }
}

.panel-brand {
  display: flex;
  margin: 30px 0 30px 14px;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 600;
  align-items: center;
  cursor: pointer;
}

.brand-logo {
  color: #3281ff;
  margin-right: 4px;
}

.logo {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 0.4rem;
}

.panel-menu {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.panel-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.bottom-menu li {
  color: #ff6347 !important;
}

.bottom-menu li:hover {
  color: #ff6347 !important;
}

.panel-menu ul li {
  display: flex;
  align-items: center;
  padding: 12px 30px;
  color: #ffffff89;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
}

.active {
  background-color: #ffffff14;
  color: #fff !important;
}

.panel-menu-item {
  height: 4.3rem;
}

.panel-menu-item-logo {
  margin-right: 5px;
}

.panel-menu ul li:hover {
  color: #fff;
}

.panel-body-cons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.panel-body__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.panel-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000079;

  z-index: 999999;
}

.undercons {
  height: 300px;
}

.undercons-warning {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
