.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

}

.options-buttons{
    height: 6rem;
    background-color: #1C273B;
    width: 90%;
    border-radius: .9rem;
    border: 1px solid #ffffff24;
    
}




.menu-button {
    font-size: 4rem;
    color: #fff;
    cursor: pointer;
    transition: all .1s ease;

}

.menu-button:hover {
    color: #ffffff68;
}

.header-right-container {
    display: flex;
    align-items: center;
    

}

.header-socket-status {
    font-size: 1.5rem;
    color: #07ec39;
    margin-right: 1.3rem;
}

.header-theme {
    font-size: 1.7rem;
    color: #ffffff;
    margin-right: 1rem;
}

.header-user {
    display: flex;
    align-items: center;
    border: 1px solid #ffffff24;
    padding: .4rem .5rem;
    border-radius: .5rem;
}

.header-user-icon{
    font-size: 3rem;
    color: #fff;
    margin-right: .3rem;
}

.header-user-text{
    font-size: 1.3rem;
    color: #fff;
}

.language-selector
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    margin-left: 1rem;

}

.lang-en {
    width: 2.5rem;
}

.language-name {
    font-size: 1.3rem;
    color: #fff;
    margin-left: .5rem;
}

.options-body {
    width: 90%;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    height: auto;


    
}

@media only screen and (max-width: 1140px) {
    .options-body {
        flex-direction: column;

    }

    .options-profile{
        width: 96% !important;
        margin-bottom: 2rem;

    }

    .options-configurations{
        width: 96% !important;

    }
    
}


.options-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
}

.options-buttons-left {
    width: 20rem;
    display: flex;
    justify-content: space-between;
}

.options-profile {
    width: 25%;
    background-color: #1C273B;
    border-radius: .8rem;
    border: 1px solid #ffffff14;

    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 55rem;



}

.profile-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    background-color: #a9a9a9;
    margin-bottom: 3rem;
}

.profile-icon{
    font-size: 9rem;
    color: #fff;
}

.profile-name{
    font-size: 2rem;
    color: #fff;
    margin-bottom: 5rem;
}

.profile-input-group {
    margin-bottom: 3rem;
}

.form-control {
    display: block;
    width: 100%;
    padding: .4375rem .75rem;
    font-family: var(--tblr-font-sans-serif);
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.4285714286;
    color: inherit;
    background-color: var(--tblr-bg-forms);
    background-clip: padding-box;
    border: var(--tblr-border-width) solid var(--tblr-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: var(--tblr-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    background-color: #1a2234;
    color: #f8fafc;
    border-color: #243049;
}

.options-configurations
{
    display: flex;
    flex-direction: column;
    height: auto;
    border: 1px solid #ffffff14;
    width: 73%;
    background-color: #1C273B;
    border-radius: .8rem;
    overflow: hidden;
}

.tab-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5rem 3rem;
}

.name-surname-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.profile-input-group-col{
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

}


.tabs-container {
    display: flex;
    border-bottom: 1px solid #ffffff14;
}

.tab {
    display: flex;
    padding: 1.3rem 2rem;
    transition: all .3s ease;
    cursor: pointer;
    

}

.tab:not(:last-child) {
    border-right: 1px solid #ffffff14;
}

.tab:hover {
    background-color: #ffffff14;
}

.tab-text {
    font-size: 1.6rem;
    color: #fff;
    margin-right: 2rem;
    
}

.tab-active {
    background-color: #ffffff14;
}

.sounds-input-group {
    display: flex;
    justify-content: space-between;
}

.custom-textbox .dx-texteditor-input {
    background-color: #1B2434;
    color: white; 
}

.custom-textbox .dx-texteditor {
    height: 5rem; 
    
}

.profile-input-group {
    margin-bottom: 20px; /* Space between input groups */
}

.profile-input-group label {
    display: block;
    margin-bottom: .5rem; 
    color: white;
    margin-left: 3px;
}

.profile-input-group-col label {
    margin-left: 3px;
    margin-bottom: .5rem; /* Space between label and input */

}

.password-change-form{
    border: 1px solid #ffffff14;
    border-radius: 5px;
    padding: 2rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-password-input{
    margin: 1.3rem 0
}



.dx-tagbox  {
    background-color: #1C273A !important; 
    color: white;
}

.dx-tagbox .dx-scrollview-content {
    background-color: #1C273A !important; 
    color: white;
}

.profile-input-group {
    margin-bottom: 20px; /* Space between input groups */
}

.input-with-button {
    display: flex;
    align-items: center;
}

