.deposit {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.deposit-body {
  width: 98%;
  background-color: #1c273b;
  border-radius: 0.5rem;
  border: 1px solid #ffffff14;
}

.deposit-pending-body {
}

.deposit-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  padding: 0 2.2rem;
  color: #fff;
  font-size: 1.6rem;
  border-bottom: 1px solid #ffffff00;
}

.deposit-header {
  display: flex;
  align-items: center;
  height: auto;
}

.deposit-header-icon {
  font-size: 2rem;
  color: #fff;
  margin-right: 0.6rem;
}

.deposit-header-refresh {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 4rem;
  font-size: 1.5rem;
  border: 1px solid #ffffff14;
  border-radius: 5px;
  padding: 0.7rem 1.7rem;
  transition: all 0.05s ease-in-out;
}

.deposit-header-refresh:hover {
  border: 1px solid #1c77d25e;
  box-shadow: 0 0 3px #1c77d25e;
}

.refresh-icon {
  font-size: 1.4rem;
  color: #fff;
  margin-right: 0.6rem;
}

.deposit-filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 2.2rem;
  color: #fff;
  font-size: 1.5rem;
  z-index: 9999;
}

.deposit-filter-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.deposit-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 4rem;
  border: 1px solid #ffffff14;
  border-radius: 5px;
  padding: 0.7rem 1.3rem;
  transition: all 0.05s ease-in-out;
}

.deposit-info:not(:last-child) {
  margin-right: 0.8rem;
}

.deposit-info:hover {
  border: 1px solid #1c77d25e;
  box-shadow: 0 0 3px #1c77d25e;
}

.money-symbol {
  margin-left: 0.25rem;
}

.deposit-input-group-old {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 4rem;
  border: 1px solid #ffffff14;
  border-radius: 5px;
  margin-right: 1.2rem;
  transition: all 0.05s ease-in-out;
}
.deposit-input-group {
  margin-right: 0.8rem;
  position: relative;
}
.deposit-filter-right {
}

.deposit-input-multiselect {
  width: 23rem;
}

.tom-select-wrapper {
  --ts-dropdown-background: #1b2434; /* Dropdown background color */
  --ts-item-background: #1b2434; /* Selected item background color */
  --ts-item-color: white; /* Selected item text color */
  --ts-dropdown-item-background: #1b2434; /* Dropdown item background color */
  --ts-dropdown-item-color: white; /* Dropdown item text color */
  --ts-border-color: #82828282; /* Border color */
  --ts-border-hover-color: #1c77d25e; /* Border color */
  --ts-placeholder-background: #1c273b; /* Placeholder background color */
}

.tom-select-wrapper .ts-control {
  background-color: var(--ts-item-background) !important;
  color: var(--ts-item-color) !important;
  border-color: var(--ts-border-color) !important;
}

.tom-select-wrapper .ts-control:hover {
  border-color: var(--ts-border-hover-color) !important;
}

.tom-select-wrapper .ts-dropdown {
  background-color: var(--ts-dropdown-background) !important;
  color: var(--ts-dropdown-item-color) !important;
  border-color: var(--ts-border-color) !important;
}

.tom-select-wrapper .ts-placeholder {
  background-color: var(--ts-placeholder-background) !important;
  color: var(--ts-item-color) !important;
}

.deposit-search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  z-index: 99999;
  font-size: 1.5rem;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2.2rem;
  color: #fff;
  font-size: 1.5rem;
}

.pagination-option {
  display: flex;
  align-items: center;
}

.pagination-option-text {
  margin-right: 1rem;
  width: 3.3rem;
  height: 3.3rem;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.pagination-option-text:hover {
  background-color: #1c77d25e;
  border-radius: 7px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-info {
  margin-right: 1.3rem;
  color: #8c8c8c;
  font-size: 1.3rem;
}

.pagination-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pagination-page-number {
  font-size: 1.5rem;
  margin: 0 2rem;
}

/* styles.css */
/* styles.css */
/* styles.css */

/* .id-column {
  color: #E4950F;
  background-color: #E4950F1A;
  font-weight: bold;
  padding: 0.7rem 1.2rem;
} */

/* .dx-datagrid-headers {
  color: #dadada !important;
  font-weight: 400;
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  border-bottom: 1px solid #29364a;
} */

.dx-overlay-shader {
  pointer-events: auto;
}

.dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #ffffff20 !important;
  visibility: visible !important;
  overflow: visible !important;
  z-index: 10000 !important;
}

.dx-accordion {
  background-color: #1b2434 !important;
}

.popup-amount-change {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.popup-body-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 17rem;
  width: 100%;
}

.popup-body-left {
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-sender {
  color: #e4950f;

  display: block;
}

.popup-person-title {
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
}

.popup-person-name {
  font-size: 1.7rem;
  text-align: center;
}

.popup-body-center {
  display: flex;

  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* margin:0 7rem; */
  height: 14rem;
}

.popup-brand {
  font-size: 1.8rem;

  color: #3d9eff;
  background-color: #1c77d235;
  padding: 0.3rem 1.2rem;
  border-radius: 7px;
}

.popup-transfer-icon {
  font-size: 2.5rem;
  color: #e4950f;
}

.popup-amount {
  font-size: 2.4rem;
  color: #89da18;
  background-color: #74b81545;
  padding: 0.3rem 1.2rem;
  border-radius: 7px;
}

.popup-body-right {
  width: 20rem;
}

.popup-body-bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rems;
}

.popup-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.3rem 0 0.3rem;
  margin-top: 2rem;
}

.popup-footer-left {
}

.dx-popup-content {
  -webkit-user-drag: none;
  /* padding: 10px !important; */
  z-index: 999999 !important;
}

.dx-popup-normal {
  background-color: #1c273b !important;
}

.dx-accordion-item-title {
  background-color: #1c273b !important;
}

.dx-accordion-item-body {
  background-color: #1c273b !important;
}

.acordion-green {
  color: #89da18;
  font-size: 1.6rem;
}

.acordion-red {
  color: #d63939;
  font-size: 1.6rem;
}
.acordion-orange {
  color: #ffb027;
  font-size: 1.6rem;
}

.deposit-detail-action {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: auto;
}

.deposit-detail-action-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0;
  /* color: #fff; */
  font-size: 1.4rem;
}

.deposit-detail-action-row--buttons {
  justify-content: space-between;
  padding: 0.7rem 0 0 0;
}

.deposit-detail-orange-button {
  background-color: #ffb027 !important;
  color: #000 !important;
}
.deposit-detail-action-button {
  width: 49%;
  font-size: 1.6rem;
}

.span-orange {
  color: #ffb027;
  font-weight: 700;
}

.popup-manual-access {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.deposit-info-table {
  overflow: visible !important;
  overflow-y: hidden !important;
  padding: 1rem 0;
}

table.customTable {
  width: 200%;
  background-color: transparent;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #370e0e3d;
  border-style: solid;
  color: #fff;
}

table.customTable td,
table.customTable th {
  border-width: 2px;
  border-color: #ffffff3d;
  border-style: solid;
  padding: 5px;
  text-align: center;
}

table.customTable thead {
}

.badge {
  font-size: 1.4rem;
  width: fit-content;
  border-radius: 7px;
  font-weight: 700;
  padding: 0.2rem 1.2rem;
  text-align: center;
  margin: 0 auto;
}

.badge-blue {
  color: #4263eb;
  background-color: #222f50;
}

.badge-pink {
  color: #ab3dc5;
  background-color: #2f2c4b;
}

.badge-orange {
  color: #f59f00;
  background-color: #3f3f46;
}

.badge-purple {
  color: #c281ff;
  background-color: #c281ff3d;
}

.badge-green {
  color: #74b815;
  background-color: #2e4247;
}

.badge-red {
  color: #d6336c;
  background-color: #39324e;
}

/* .dx-overlay-wrapper {
  overflow-x: hidden !important;
  overflow-y: auto !important;

} */

/* .dx-scrollable-content{
  background-color: #1C273B !important;
  min-height: 60vh !important;
  max-height: 60vh !important;

} */

.deposit-table {
  /* --lolo : 60rem; */

  width: 100% !important;
  height: var(--lolo) !important;
  /* height: 100% !important; */
  padding: 0 2.2rem 1.5rem 2.2rem;
  display: flex;
  flex-direction: column;
  /* align-items: center;  */
  /* margin-top: -6.5rem; */

  /* margin-top:1rem; */
  color: hsl(0, 0%, 79%);
}

.dx-widget {
  max-width: 100% !important;
}

.ag-theme-quartz {
  /* Changes the color of the grid text */
  --ag-foreground-color: white !important;
  /* Changes the color of the grid background */
  --ag-background-color: "#1C273B" !important;
  /* Changes the header color of the top row */
  --ag-header-background-color: rgba(28, 119, 210, 0.166) !important;
  /* Changes the hover color of the row*/
  --ag-row-hover-color: #1b2434 !important;
}

.dx-datagrid-header-panel {
  background-color: transparent !important;
}

.dx-datagrid .dx-scrollable-container {
  background-color: #1c273b !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  background-color: #1b2434 !important;
  color: #fff !important;
}

.datagrid-column {
  border-left: 1px solid #ffffff18 !important;
  border-right: 1px solid #ffffff18 !important;
}

.rezerv-column,
.detail-column {
  background-color: #1c273b !important;
}

.dx-col-14-fixed,
.dx-col-15-fixed {
  background-color: #1c273b !important;
}

/* 
.dx-datagrid .dx-data-row .dx-row > td, 
.dx-datagrid .dx-header-row .dx-row > td {
  border-left: 1px solid #ffffff18 !important; 
  border-right: 1px solid #ffffff18 !important;
  border-color: #ffffff18 !important;
} */

/* .dx-datagrid .dx-row.dx-selection,
.dx-datagrid .dx-data-row.dx-selection,
.dx-datagrid .dx-row.dx-state-focused,
.dx-datagrid .dx-data-row.dx-state-focused {
    background-color: #0000003c !important; 

} */

.grid-badge {
  font-size: 1.5rem;
  padding: 0.5rem 1.2rem;
  border-radius: 7px;
  font-weight: 700;
}

.deposit-fixed-column {
  border-right: 1px solid #000 !important;
}

.grid-all-rezerv {
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: #4263eb;
  color: white;
}

.grid-freeandmy-rezerv {
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: #d43739;
  color: white;
}

.grid-all-detail {
  padding: 0.5rem 1.4rem;
  border-radius: 4px;
  background-color: #f59f00;
  color: rgb(255, 255, 255);
  font-weight: 700;
  cursor: pointer;
}

.grid-freeandmy-detail {
  padding: 0.5rem 1.3rem;

  border-radius: 4px;
  background-color: #f59f00;
  color: white;
}

.grid-cell-amount {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.grid-amount {
  text-align: right;
  /* margin-right: 1.2rem; */
}

.grid-amount-button {
  font-size: 2rem;

  cursor: pointer;
  color: #4294d9;
  background-color: #4294d92a;
  border-radius: 2px;
  /* margin-right: 1.5rem; */
  margin-left: 1.5rem;
}

.trc-status {
  width: fit-content;
  padding: 0.2rem 1.2rem;
  border-radius: 7px;
  color: #f8f5f1;
  text-align: center;
  margin: 0 auto;
}

.trc-orange {
  background-color: #f59f00;
}

.trc-green {
  background-color: #74b815;
}

.info-button {
  font-size: 1.4rem !important;
}

.info-button--amount {
  font-size: 1.4rem !important;
}

.info-button:hover {
  border-color: #4294d9;
}

.info-button:hover,
.info-button:focus,
.info-button:active {
  background-color: transparent !important;
}

.info-report-body {
  width: 23rem !important ;
}

.deposit-view-table {
  margin-top: 1rem;
}

.minmax-input,
.transaction-type-select .info-button {
  background-color: #1b2434 !important;
}

.depositContainer {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}

.deposit-item {
  margin-right: 1rem;
  border: 2px solid #fff;
  padding: 1rem;
}

.accordion-body {
  background-color: #1c273b !important;
}

.accordion-button {
  background-color: #1c273b !important;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.confirmation-box {
  padding: 0 2rem;

  text-align: center;
  width: 100%;
}

.icon-info {
  font-size: 5rem;
  color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-red {
  color: #dc3545;
}

.icon-green {
  color: #28a745;
}

.transaction-info {
  margin: 20px 0;
}

.info {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.label {
  color: #f1f1f1;
  font-size: 1.4rem;
  font-weight: 700;
}

.value {
  font-size: 1.4rem !important;
  margin-left: 0;
  margin-right: 0;
}

.amount {
  font-size: 2.4rem;
  margin-top: 2rem;
  color: #ff6f00;
}

.currency {
  font-size: 18px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.amount-filter-container {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.dx-state-focused.dx-accordion-item {
  border-color: transparent !important;
}

.dx-loadpanel-wrapper .dx-overlay-content.dx-loadpanel-content {
  visibility: hidden !important;
  display: none !important;
}

.user-history-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.user-history-icon {
  color: #4263eb;
  cursor: pointer;
  font-size: 1.6rem;
  margin-left: 0.2rem;
}

.user-history-icon:hover {
  color: #4264eb7c;
}

.user-history-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.history-info-box {
  /* border-radius: 5px; */
  border: 1px solid black;
  background-color: #1b2434;
  display: flex;
  flex-direction: row;
  margin-right: 1.2rem;
  padding: 0.3rem 0.5rem;
}

.history-info-box-title {
  font-size: 1.5rem;
  margin-right: 1.2rem;
}

.history-info-box-value {
  color: orange;
}

.info-history {
  margin-right: 5rem !important;
}
