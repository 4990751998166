.payment-gateway-body{
    height: 68rem;
    min-height: 68rem;
}


.pg-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: 35rem;
    height: auto;
    /* min-height: 68rem; */
    background-color: #f0f0f0;

    box-shadow: 0px 0px 10px 0px #000000;
    border-radius: 15px;
    padding: 3rem 0rem;


    
}

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.p-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 7rem;
    color: #0E1825; /* Netflix kırmızısı */
    font-family: 'Bebas Neue', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
    position: relative;
    
    /* height: 150px; */
    margin-bottom: 3rem;
}



.p-header::before,
.p-header::after {
    content: attr(data-text); /* Aynı metni eklemek için */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #0E1825; /* Netflix kırmızısı */
    z-index: -1;
}

.p-header::before {
    transform: rotate(-2deg);
    filter: brightness(85%);
}

.p-header::after {
    transform: rotate(2deg);
    filter: brightness(70%);
}

.cc-info-form {
    margin: 2rem 0
}

.dx-field-label{
    font-size: 1.5rem;
    font-weight: 600;
    color: #0E1825 !important;
}

.pay-button {
    margin-top: 1rem !important;
}

.payment-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #007BFF;
    font-size: 2rem;
    font-weight: 600;
    margin: 3rem 0
}

.loader-text-stage-1{
    margin-top: 1.5rem !important;
}

.sms-code-form{
    margin: 2rem 0;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #007BFF; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .payment-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    /* background-color: #f8f9fa85;  */
    /* padding: 4rem 0; */
    margin: 3rem 0 5rem 0
  }
  
  .result-icon {
    color: #28a745; /* Success color */
    font-size: 6rem; /* Larger icon size */
    margin-bottom: 1rem;
  }
  
  .result-text-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .result-text {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .result-subtext {
    font-size: 1.4rem;
    color: #555;
    font-weight: 500;
  }

  .countdown-timer {
    margin-top: 10px; /* Adds some space above the timer */
    font-size: 18px; /* Sets a larger font size for visibility */
  
    font-weight: bold; /* Makes the text bold */
    text-align: center; /* Centers the text horizontally */
    background-color: #ffecec; /* Light red background for emphasis */
    border: 1px solid transparent; /* Border matching the text color */
    border-radius: 5px; /* Rounded corners */
    padding: 5px; /* Padding for some breathing space inside the box */
    display: inline-block; /* Shrinks the width to fit the content */
    width: auto; /* Allows the width to adjust automatically */
  }

  .countdown-timer--red {
    color: #ff4444; /* Red color to indicate urgency */
    background-color: #ffecec; /* Light red background for emphasis */
    border-color: #ff4444; /* Border matching the text color */
  }

  .countdown-timer--green {
    color: #28a745; /* Green color to indicate success */
    background-color: #e6ffed; /* Light green background for emphasis */
    border-color: #28a745; /* Border matching the text color */
  }

  .p-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;

  }