.deposit-report {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.deposit-report-container {
  width: 98%;
  background-color: #1c273b;
  border-radius: 0.5rem;
  border: 1px solid #ffffff14;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.deposit-report-filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.3rem 2.2rem;
  color: #fff;
  font-size: 1.5rem;
}

.deposit-report-filter-top {
  display: flex;
  justify-content: space-between;
}
.deposit-report-info-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding-left: 0 !important;
}

@media only screen and (max-width: 1140px) {
  .deposit-report-filter-top {
    flex-direction: column;
  }

  .deposit-report-info-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding-left: 0 !important;
  }

  .info-approve {
    margin-bottom: 2rem;
  }

  .deposit-report-info-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .info-approve {
    width: 100% !important;
  }

  .info-decline {
    width: 100% !important;
  }
}

.deposit-report-filter-bottom {
  display: flex;
  justify-content: space-between;
}

.deposit-report-info-left {
}
.info-report-body {
  width: 23rem !important ;
}
.info-approve {
  margin-right: 10rem;
  scale: 0.95;
}

.info-decline {
  scale: 0.95;
}

.deposit-report-info-right {
  display: flex;
  align-items: center;
}

.deposit-report-input-group:not(:last-child) {
  margin-right: 2rem;
}

.rs-date-range-input {
  background-color: #1b2434 !important;
  color: #fff !important;
}

.rs-picker-daterange-panel {
  background-color: #1b2434 !important;
  color: #fff !important;
}

.rs-picker-daterange-panel {
  border: 1px solid rgb(110, 110, 110) !important;
  border-radius: 5px !important;
}

.dx-overlay-shader {
  /* overflow-y: auto !important; */
}

.dx-popup-normal {
}

.report-table {
  height: 100vh !important;
}

.deposit-report-container {
  height: auto !important;
}

.rs-input-group {
  background-color: #1b2434 !important;
}

.rs-input-group.rs-input-group-inside {
  background-color: #1b2434 !important;
  border: 1px solid #ffffff14;
}

.grid-search-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.grid-search-container-left {
  background-color: #1b2434;
  padding: 1rem 2rem;
  border-radius: 4px;
  color: #dedede;
  font-size: 1.6rem;
  border: 1px solid #29364a;
  display: flex;
  align-items: center;
  transition: border-color 0.2s ease;
}

.grid-search-container-left:hover {
  border-color: #225978;
}
