.InCard{
    display:flex;
    flex-direction:column;
    align-items: center;
    height: auto;
}

.incard-header-container{
    margin-bottom: 1rem;
}


  .grid-id-col, .grid-transaction-id-col{
    background-color: #1C273B !important;
    border-right: 1px solid #000 !important
}

.grid-button-incard{
    color: #fff;
    padding: 0.4rem 1.4rem;
    font-size: 1.5rem;
    border-radius: 3px;
    cursor: pointer;

}

.grid-approve-button{
    background-color: #73B816;
}

.grid-decline-button{
    background-color: #D53839;
}

.approve-column, .decline-column {
    background-color: #1C273B !important;
    border-right: 1px solid #000 !important
}

.transaction-type-select {
    background-color: #1B2434 !important;
  }
  