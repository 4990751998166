.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background-color: #1a1a1a;
}

.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 3rem 1rem;
}

.login__container__header {
  margin-bottom: 2rem;
  font-size: 3rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container__header img {
  width: 5rem;
  height: 5rem;
  margin-right: 0.5rem;
}

.login__container__header__text {
  font-size: 3rem;
  color: #000;
  font-weight: 700;
}

.login__container__card {
  width: 45rem;
  min-height: 40rem;
  height: auto;
  padding: 3rem 3rem 0.5rem 3rem;
  background-color: #d4d4d4;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, x 0.75);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media only screen and (max-width: 700px) {
  .login__container {
    width: 90% !important;
  }

  .login__container__card {
    width: 100%;
  }
}

.login__container__card-header {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.input__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.6rem;
}

.login__container__language {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: 1.5rem;
  color: #929292;
}

.login__container__language__text {
  padding: 0 0.5rem;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.login__container__language__text:hover {
  color: #000;
}

.otp-container {
  text-align: center;
  width: 100%;
}

.otp-container h3 {
  margin-bottom: 20px;
}

.seperator {
  color: #fff;
}

.otp-input {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  margin: 0 auto;
  color: #000;
}

.buttons-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.buttons-container .dx-button {
  margin: 0 10px;
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.inputStyle {
  width: 8rem !important;
  height: 10rem;
  margin: 0 1rem;
  font-size: 4rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #000;
}

.buttons-container {
  height: 3rem;
  margin-top: 4rem;
}
