.cash-operations{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cash-operations-top{
    margin-bottom: 2rem;
}

.cash-operations-bottom{
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    margin-top:3rem;

}

@media only screen and (max-width: 1140px) {

    .cash-operations-top{
        flex-direction: column;
        align-items: flex-start;
    }

    .cash-operations-bottom{
        flex-direction: column;
        
    }
    
    .information-container, .info-approve{
        width: 100%;
    }
}

.info-tag--transferred{
    color: #E4950F;
    background-color: #E4950F1A;
}

.cash-operations-info-body{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    margin-bottom: 1rem;
    margin-top:1rem;
}

.info-body-top{
    display:flex;
    margin-bottom: 1rem;
}

.info-transferred-icon{
    color: #E4950F;
}

.info-tag--transferred-desc{
    font-size: 1.4rem;
    color : #89da18;
    background-color: #74b81545;
    font-weight: 400;
    
}

.info-green-icon{
    color: #89da18;
}

.info-tag--red{
    color: #D63939;
    background-color: #d639393b;

}

.info-red-icon{
    color: #D63939;
}

.info-tag--orange-desc{
    font-size: 1.4rem;
    color : #E4950F;
    background-color: #E4950F1a;
    font-weight: 400;
}

.info-tag--blue{
    color: #0A84FF;
    background-color: #0A84FF1a;
}

.info-blue-icon{
    color: #0A84FF;
}

.info-tag--blue-desc{
    font-size: 1.4rem;
    color : #0A84FF;
    background-color: #0A84FF1a;
    font-weight: 400;
}

.cash-filters-2{
    padding: 0 !important;
    margin-bottom: 1rem;
}

.cash-table{
    padding: 0 !important;
}
