.options-header {
    width: 100%;
    

    background-color: #1C273B;
    margin-bottom: 1rem;
    border-radius: .3rem;

    display: flex;

    flex-direction: column;

    align-items: center;
    padding: 0 1rem;

    height: auto;
}

.header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 5rem;;
    height: 5rem;
}

.header-navigation{
    display: flex;
    flex-direction: column;
    width: 100%;
}



.panel-menu-header{
    width: 100%;
}

.panel-menu-header-hide{
    display: none;
}

.header-nav-list{
    margin-bottom: 0 !important;
}

.header-nav-item{
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


