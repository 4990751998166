.InCardReport{
    display:flex;
    flex-direction:column;
    align-items: center;
    height: auto;

}

.report-detail-popup{
    padding: 0 1rem;
}

.report-detail-footer{
    padding: 1rem 0 0 0;
}

.table_component {
    overflow: auto;
    width: 100%;
  }
  
  .table_component table {
    height: 100%;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: left;
  }
  
  .table_component caption {
    caption-side: top;
    text-align: left;
  }
  
  .table_component th {
    border: none;
    color: #fff;

  }
  
  .table_component td {
    border: none;
    color: #fff;
    padding: 1.2rem;
    border-bottom: 1px solid #ffffff18;
  }
  
  .table_component td:not(:last-child) {
    border-right: 1px solid #ffffff18;
    
  }

  .incard-fixed-column{
    background-color: #1C273B !important;
    border-right: 1px solid #000 !important;
  }