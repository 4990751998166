/* input için basit bir koyu tema stili */
.jq-date-input {
  width: 100%;
  background-color: #1b2434;
  border: 1px solid #29364a;
  color: #dedede;
  height: 3rem;
  font-size: 1.6rem;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  outline: none;
}

/* daterangepicker penceresinin arka plan ve metin rengi */
.daterangepicker {
  background-color: #1b2434 !important;
  color: #dedede !important;
  border: 1px solid #29364a !important;
}

/* Takvim tablolarındaki başlık ve hücre yazı rengi */
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  color: #dedede !important;
}

/* Tarih seçili hale gelince arkaplan rengi */
.daterangepicker .drp-selected,
.daterangepicker .active {
  background-color: #19a9dd !important;
  color: #fff !important;
}

/* Range listesindeki (Today, Yesterday vb.) metin */
.daterangepicker .ranges li {
  color: #dedede !important;
}

/* Apply (Uygula) ve Cancel (İptal) butonları */
.daterangepicker .drp-buttons .applyBtn,
.daterangepicker .drp-buttons .cancelBtn {
  background-color: #29364a !important;
  border-color: #225978 !important;
  color: #dedede !important;
}

/* Hover olduğunda buton rengi */
.daterangepicker .drp-buttons .applyBtn:hover,
.daterangepicker .drp-buttons .cancelBtn:hover {
  background-color: #225978 !important;
  color: #fff !important;
}

/* Takvim tablolarının arkaplanı, hücre çizgileri, hücre yazıları */
.daterangepicker .calendar-table {
  background-color: #1b2434 !important;
  border-color: #29364a !important;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  color: #dedede !important;
  background-color: #1b2434 !important;
  border: 1px solid #29364a !important;
}
.daterangepicker .calendar-table td.off {
  color: #888 !important; /* Geçerli ayda olmayan günleri biraz daha soluk göster */
}

/* Tarih seçili hale gelince arkaplan ve yazı rengi */
.daterangepicker .drp-selected,
.daterangepicker .in-range,
.daterangepicker .active {
  background-color: #225978 !important;
  color: #fff !important;
}

/* Takvimde önceki/sonraki ay butonları */
.daterangepicker .prev,
.daterangepicker .next {
  color: #dedede !important;
  opacity: 0.8;
}
.daterangepicker .prev:hover,
.daterangepicker .next:hover {
  color: #fff !important;
  opacity: 1;
}

/* Saat/dakika/saniye select alanları */
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  background-color: #29364a !important;
  color: #dedede !important;
  border: 1px solid #29364a !important;
  outline: none !important;
}

/* Apply ve Cancel butonları */
.daterangepicker .drp-buttons .applyBtn,
.daterangepicker .drp-buttons .cancelBtn {
  background-color: #29364a !important;
  border: 1px solid #225978 !important;
  color: #dedede !important;
}
.daterangepicker .drp-buttons .applyBtn:hover,
.daterangepicker .drp-buttons .cancelBtn:hover {
  background-color: #225978 !important;
  color: #fff !important;
}

/* Alt taraftaki seçili tarih gösterimi */
.daterangepicker .drp-calendar .calendar-time,
.daterangepicker .drp-calendar .calendar-inputs input {
  background-color: #1b2434 !important;
  color: #dedede !important;
  border: 1px solid #29364a !important;
}

/* Soldaki preset aralıklar (Today, Yesterday vs.) hover */
.daterangepicker .ranges ul li:hover {
  background-color: #225978 !important;
  color: #fff !important;
}

/* Takvim hücreleri hover */
.daterangepicker .calendar-table td:hover {
  background-color: #225978 !important;
  color: #fff !important;
}

/* Önceki/Sonraki ay ok butonları hover */
.daterangepicker .prev:hover,
.daterangepicker .next:hover {
  color: #fff !important;
  opacity: 1;
}

/* Apply ve Cancel butonları hover */
.daterangepicker .drp-buttons .applyBtn:hover,
.daterangepicker .drp-buttons .cancelBtn:hover {
  background-color: #225978 !important;
  color: #fff !important;
}
