.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-information-container {
  width: 96%;
  display: flex;
  height: auto;
  justify-content: space-between;
}

@media only screen and (max-width: 1140px) {
  .dashboard-information-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .information-deposit {
    width: 96% !important;
    margin-bottom: 2rem;
  }

  .information-incard {
    width: 96% !important;
    margin-bottom: 2rem;
  }

  .information-filters {
    width: 96% !important;
  }

  .dashboard-chart {
    width: 96% !important;
    margin-bottom: 2rem;
  }
}

.information-deposit {
  height: 20rem;
  width: 39%;

  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff24;
  border-radius: 5px;
  background-color: #1c273b;
}

.information-incard {
  height: 20rem;
  width: 39%;

  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff24;
  border-radius: 5px;
  background-color: #1c273b;
}

.information-filters {
  height: 20rem;
  width: 18%;

  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff24;
  border-radius: 5px;
  background-color: #1c273b;
}

.information-header {
  font-size: 1.6rem;
  padding: 1.3rem 2rem;
  color: #fff;
  font-weight: 700;
  border-bottom: 1px solid #ffffff24;
  display: flex;
  align-items: center;
}

.information-header--deposit {
  color: #89da18;
}

.information-header--incard {
  color: #d63939;
}

.information-header--filter {
  color: #e4960e;
}

.information-header-icon {
  margin-right: 0.6rem;
  font-size: 2.3rem;
}

.information-body {
  display: flex;
  flex-direction: row;
  padding: 1.5rem 2rem;
}
.information-container {
  width: 25%;
}

.information-accepted-container {
  width: 48%;
}

.information-rejected-container {
  width: 48%;
}

.info-tag {
  display: inline;
  padding: 0.3rem 0.7rem;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 700;
}

.info-tag--accepted {
  color: #89da18;
  background-color: #74b81545;
}

.info-tag--rejected {
  color: #d63939;
  background-color: #d639393b;
}

.info-body {
  margin-top: 2rem;
  display: flex;
}

.info-icon {
  font-size: 4rem;
}

.info-icon--reject {
  color: red;
}

.info-count-container {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.info-amount-container {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.info-header {
  color: #979da2;
  font-size: 1.3rem;
  margin-bottom: 0.4rem;
}

.info-text {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
}

.info-filter-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-input-group {
  width: 90%;
  margin-top: 1.2rem;
}

.dashboard-chart {
  width: 96%;
  height: auto;
  margin-top: 2rem;
  border: 1px solid #ffffff24;
  border-radius: 5px;
  background-color: #1c273b;
}
